import { type LocationEntity } from "@repo/api-client";
import { type BreadCrumbItem } from "../components/breadcrumb";
import { type LocationFrontData } from "../components/store-locator/types";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace LocationUtils {

  export function computeLocationBreadcrumbItems(location: Omit<LocationEntity | LocationFrontData, 'centers'>) : BreadCrumbItem[] {
    const locationItems: BreadCrumbItem[] = [];

    let loc: typeof location | undefined = location;
    while (loc !== undefined) {
      locationItems.push({
        label: loc.name,
        url: `/centres-de-selfstockage/${loc.path!}`,
      });
  
      loc = loc.parent;
    }
  
    return [
      {
        label: 'Accueil',
        url: '/',
      },
      {
        label: 'Nos centres',
        url: '/centres-de-selfstockage',
      },
      ...locationItems.reverse(),
    ];
  }

}