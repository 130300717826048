export type FormatCenterNameArgs = {
  /**
   * The town name where the center is actually in 
   * @example "Saint-Herblain"
   */
  town: string,
  
  /**
   * The center closest big city
   * @example "Nantes"
   */
  bigCity?: string | undefined | null,

  /**
   * Defines how the center `bigCity` will be separated from the `town`
   */

  /**
   * If present will add the location code 
   * @example "44"
   */
  locationCode?: string;
  
  /**
   * Defines how the center `bigCity` will be separated from the `town`
   */
  separator?: 'parenthesis' | 'hypen';
}

/**
 * Formats the center name to include the `town` and `bigCity` properties
 * 
 * @param args 
 * @returns 
 */
export function formatCenterName({town, bigCity, separator = 'hypen'} : FormatCenterNameArgs) : string {
  let output = town;

  if (bigCity) {
    output += (separator === 'parenthesis')
      ? ` (${bigCity})`
      : ` - ${bigCity}`
  }

  return output;
}