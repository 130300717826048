export async function hashSHA256(...inputs: Array<string | undefined>): Promise<Array<string | undefined>> {
  return Promise.all(
    inputs.map(async (input) => {
      if (!input)
        return undefined;

      const utf8 = new TextEncoder().encode(input);
      const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray
        .map((bytes) => bytes.toString(16).padStart(2, '0'))
        .join('');
      return hashHex;
    }),
  );
}
