import { type TailwindScreensNames, tailwindScreens } from './tailwind-utils';
import { type MediaEntity } from '@repo/api-client';

export function parseImage(image: MediaEntity) : MediaEntity {
  return image;
}

export function resolveImagePath(path: string) : string {
  const apiUrl = process.env.NEXT_PUBLIC_API_URL;
    
  if (!apiUrl)
    throw new Error(`NEXT_PUBLIC_API_URL is not defined`);
  
  return `${apiUrl}uploads/${path}`;
}

type ImagesSizes = Partial<Record<TailwindScreensNames, number>>;

/**
 * Renders the Image size attributes for an image according to tailwind screens breakpoints
 * 
 * @param base The base % size on mobile of the image
 * @param sizes An object with every tailwind screen breakpoints and you can set the image size in %
 * @returns 
 */
export function imageSizes( base: number, sizes?: ImagesSizes ) : string {
  let output = '';

  if (!sizes)
    return `${base}vw`;

  for (const screenName of Object.keys(tailwindScreens) as Array<keyof ImagesSizes>) {
    if ( !sizes[screenName] )
      continue;

    output += `(min-width: ${tailwindScreens[screenName]}px) ${sizes[screenName]}vw,`;
  }

  return `${output} ${base}vw`;
}