import { type MultiCartProviderItem } from '../cart';
import { LocalStorageWriter } from './local-storage-writer';
import { type StorageWriterInterface } from './storage-writer-interface';

type Content = Map<string, MultiCartProviderItem>;

export class CartItemWriter implements StorageWriterInterface<Content> {
  private readonly storageWriter: StorageWriterInterface<Record<string, MultiCartProviderItem>>;
  
  constructor() {
    this.storageWriter = new LocalStorageWriter('ss_carts_items');
  }

  read(): Content | undefined {
    const result = this.storageWriter.read();
    
    if (!result) return;

    return new Map(
      Object.entries(result)
    );
  }

  write(data?: Content): void {
    if (!data)
      return this.storageWriter.write();
    return this.storageWriter.write( Object.fromEntries(data.entries()) );
  }

  set(item: MultiCartProviderItem): void {
    const items = this.read() ?? new Map();

    // Delete it first to put the item in last position array to make getLast() work
    items.delete(item.centerId);
    items.set(item.centerId, item);

    this.write(items);
  }

  private deleteCheckoutCart(cartId: string) {
    if (typeof window === 'undefined')
      return;

    window.localStorage.removeItem(`ss_checkout_cart_${cartId}`);
  }

  deleteByCartId(cartId: string): void {
    const items = this.read();
    if (!items) return;
    
    const target = Array.from(items.values()).find((item) => item.cartId === cartId);
    if (!target)
      return

    items.delete(target.centerId);
    this.deleteCheckoutCart(cartId);
    this.write(items);
  }

  deleteByCenterId(centerId: string): void {
    const items = this.read();

    if (!items) return;
    if (!items.has(centerId)) return;

    const {cartId} = items.get(centerId)!;
    this.deleteCheckoutCart(cartId)

    items.delete(centerId);
    this.write(items);
  }

  getByCartId( cartId: string ) : MultiCartProviderItem | undefined {
    const items = this.read();

    if (!items) return;

    return Array.from(items.values()).find(item => item.cartId === cartId);
  }

  getByCenterId( centerId: string ) : MultiCartProviderItem | undefined {
    const items = this.read();

    if (!items) return;

    return items.get(centerId);
  }

  getLast() : MultiCartProviderItem | undefined {
    const items = this.read();

    if (!items) return;

    const itemsArr = Array.from(items.values());
    return itemsArr[ itemsArr.length - 1 ];
  }

  listItems() : MultiCartProviderItem[] | undefined {
    const items = this.read();

    if (!items)
      return;

    return Array.from(items.values());
  }

  clear(): void {
    this.write();
  }
}
