import {
  type BoxProductLineEntity,
  CartApi,
  type CreateCartDTO,
  type ProductLineDTO,
  type ProductLineEntity,
  type ResponseCartCouponDTO,
  type ResponseCartDTO,
} from '@repo/api-client';
import { AxiosError, type AxiosInstance } from 'axios';
import { type CreateCartClientDTO, type UpdateCartClientDTO, type CartClientDTO, type CreateOrUpdateCartClientDTO, type CartClientErrorMetadata } from './types';
import { parseErrorsFlat } from '@repo/utils';
import { CartClientError } from './cart-client-error';
import { type CartClientRequestOptions } from './types';


export class CartClient {
  private readonly client: CartApi;

  constructor( client: AxiosInstance ) {
    this.client = new CartApi(undefined, '', client);
  }

  private handleError(e: unknown, data?: CartClientErrorMetadata): CartClientError {
    if (e instanceof AxiosError) {
      if (e.response?.status === 404)
        return new CartClientError({
          type: 'NOT_FOUND',
          message: "Vous n'avez aucun box dans votre panier",
          cartId: data?.cartId,
        });

      if (e.response?.status === 400) {
        const res = e.response.data;
        return new CartClientError({
          type: 'BAD_REQUEST',
          cartId: data?.cartId,
          message: res.errors !== undefined 
            ? parseErrorsFlat(res.errors)
            : res.message
        })
      }
    }

    return CartClientError.Unknown();
  }

  private convertCartToDTO(cart: ResponseCartDTO | CartClientDTO, options?: CartClientRequestOptions) {
    return {
      id: undefined,
      centerId: cart.centerId!,
      messages: [],
      productLines: this.convertLinesToDTO(
        options?.mergeLines
          ? this.mergeLines(cart.productLines ?? [])
          : this.overrideLines(cart.productLines ?? [])
      ),
      coupons: (cart.coupons ?? []).map(coupon => this.convertCouponToDTO(coupon))
    } satisfies CreateCartDTO;
  }

  private convertCouponToDTO(couponOrCouponCode: ResponseCartCouponDTO | string) : string {
    if (typeof couponOrCouponCode === "string")
      return couponOrCouponCode;

    return couponOrCouponCode.code;
  }

  /**
   * Converts a product line DTO to be compatible with API DTO requirements
   * @param line
   * @returns
   */
  private convertLinesToDTO(lines: Array<ProductLineDTO | ProductLineEntity | BoxProductLineEntity>): ProductLineDTO[] {
    return lines.map((line) => ({
      productId: line.productId,
      qty: line.qty,
      month: "month" in line ? line.month : undefined,
      contractStartingDate: "contractStartingDate" in line ? line.contractStartingDate : undefined,
    }));
  }

  /**
   * Merge lines quantities together. Like if they are an array of lines with duplicates IDs, it will merge duplicates and sum their quantities together
   * @param lines
   * @returns
   */
  private mergeLines(lines: Array<ProductLineDTO>): ProductLineDTO[] {
    const linesMap: Record<string, ProductLineDTO> = {};

    for (const line of lines) {
      const lineId = line.productId;

      if (!linesMap[lineId]) {
        linesMap[lineId] = line;
        continue;
      }

      linesMap[lineId].qty! += line.qty ?? 0;
    }

    return Object.values(linesMap);
  }

  private overrideLines(lines: ProductLineDTO[]) : ProductLineDTO[] {
    const linesMap: Record<string, ProductLineDTO> = {};

    for (const line of lines) {
      linesMap[line.productId] = line;
    }

    return Object.values(linesMap);
  }

  async get(cartId: string): Promise<ResponseCartDTO> {
    try {
      const response = await this.client.cartControllerFindOne(cartId);

      if (response.status === 200) return response.data.data;

      throw response;
    } catch (e) {
      throw this.handleError(e, {
        cartId
      });
    }
  }

  async create(
    dto: CreateCartClientDTO,
    options?: CartClientRequestOptions
  ): Promise<ResponseCartDTO | never> {
    try {
      const response = await this.client.cartControllerCreate(
        this.convertCartToDTO(dto, options)
      );
      return response.data.data;
    } catch (e) {
      throw this.handleError(e);
    }
  }

  async update(
    cartId: string,
    dto: UpdateCartClientDTO,
    options?: CartClientRequestOptions,
  ): Promise<ResponseCartDTO> {
    try {
      const response = await this.client.cartControllerUpdate(
        cartId,
        this.convertCartToDTO(dto, options),
      );
      return response.data.data;
    } catch (e) {
      throw this.handleError(e, {
        cartId
      });
    }
  }

  async createOrUpdate(dto: CreateOrUpdateCartClientDTO, options?: CartClientRequestOptions) : Promise<ResponseCartDTO> {
    if (dto.id) {
      const updateDTO = {...dto};
      delete(updateDTO.id);

      return this.update(dto.id, updateDTO, options);
    }

    return this.create(dto as CreateCartClientDTO, options);
  }
}
