import { ParsedValidationErrors, ValidationErrorData } from "./types";

export function parseErrors(validationErrors: ValidationErrorData[]) : ParsedValidationErrors {
  const output: ParsedValidationErrors = {};

  for (const error of validationErrors) {
    if (error.messages) {
      output[error.property] = error.messages;
    } else {
      output[error.property] = parseErrors(error.errors);
    }
  }

  return output;
}

function _parseErrorFlatItem( error: ValidationErrorData ) : string[] {
  const output = [];

  if (error.messages) {
    output.push(...error.messages);
  } else {
    for (const e of error.errors) {
      output.push(..._parseErrorFlatItem(e));
    }
  }

  return output;
} 

export function parseErrorsFlat( errors: ValidationErrorData[] ) : string {
  return errors.map((e) => _parseErrorFlatItem(e))
    .join('\n');
}