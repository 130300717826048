import {
  type BoxDimensions,
  type BoxProductCardData,
  type BoxProductEntity,
  type ProductEntity,
} from '@repo/api-client';
import { convertNumberToDisplayablePriceString } from '@repo/utils';
import { type ReactNode } from 'react';
import { type BreadCrumbItem } from '../components/breadcrumb';
import { Exp } from '../components/exp';
import { getCenterBreadcrumbItems } from './center-utils';
import { type StockStatusEnum, type ProductGridCardProps } from '../components/products/types';

export function renderProductUrl(productPath: string) {
  return `/centres-de-selfstockage/${productPath}`;
}

export function getBoxProductBreadcrumbItems(
  product: BoxProductEntity
): BreadCrumbItem[] {
  try {
    const centerItemsParts = getCenterBreadcrumbItems(product.center);
    return [
      ...centerItemsParts,
      {
        label: 'Boxes',
        url: `${centerItemsParts?.at(-1)?.url}/boxes`,
      },
      {
        label: product.boxType.name! as unknown as string,
        labelNode: (
          <span className="mt-[-0.1875rem] inline-block">
            Nos boxes {renderBoxName(product)}
          </span>
        ),
        url: renderProductUrl(product.path!),
      },
    ];
  } catch (e) {
    return [];
  }
}

export function convertCmToM(cm: number): string {
  return (cm / 100).toFixed(2).toString().replace('.00', '').replace('.', ',');
}

export type RenderBoxNameParams = {
  boxType: {
    surface: number;
    volume: number;
  };
};

export function renderBoxName(product: RenderBoxNameParams): ReactNode {
  return (
    <>
      {convertCmToM(product.boxType.surface)}m<Exp>2</Exp>
    </>
  );
}

type RenderProductParams = {
  name: string;
};

export function renderProductName(
  product: RenderProductParams | RenderBoxNameParams
) {
  if ('boxType' in product) {
    return renderBoxName(product);
  } else {
    return product.name;
  }
}

export function renderBoxNameString(product: RenderBoxNameParams): string {
  return `${convertCmToM(product.boxType.surface)}m²`;
}

export function convertStockToStockStatus(
  stock: number,
  manageStocks: boolean = true
): StockStatusEnum {
  if (stock > 3 || !manageStocks) return 'available';

  if (stock > 0) return 'limited';

  return 'outOfStock';
}

export function renderBoxDimensions(
  dimensions: string | BoxDimensions,
  inline: boolean = false
): ReactNode {
  if (typeof dimensions === 'string') return dimensions;

  if (inline)
    return (
      <>
        {convertCmToM(dimensions.length)}m x {convertCmToM(dimensions.width)}m x{' '}
        {convertCmToM(dimensions.height)}m
      </>
    );

  return (
    <ul className={'flex flex-col gap-2 space-y-0 sm:flex-row sm:space-x-6'}>
      <li>Longueur : {convertCmToM(dimensions.length)}m</li>
      <li>Largeur : {convertCmToM(dimensions.width)}m</li>
      <li>Hauteur : {convertCmToM(dimensions.height)}m</li>
    </ul>
  );
}

export function retrieveLinkedProductByCategory(
  product: ProductEntity | BoxProductEntity | BoxProductCardData,
  categorySlug: string
): ProductEntity | undefined {
  if (!product.linkedProducts) return undefined;

  return product.linkedProducts.find(
    (product) =>
      product.categories &&
      product.categories.find((category) => category.slug === categorySlug)
  ) as ProductEntity;
}

export function retrieveBoxBookingFees(
  box: BoxProductEntity | BoxProductCardData
) {
  return retrieveLinkedProductByCategory(box, 'booking-fees');
}

export function convertBoxToProductGridCard(
  box: BoxProductEntity | BoxProductCardData,
  onAddToCart: () => void
): ProductGridCardProps {
  return {
    hasOffer: false, // TODO has offer
    image: box.boxType.cover,
    surface: convertCmToM(box.boxType.surface),
    volume: convertCmToM(box.boxType.volume),
    stockStatus: convertStockToStockStatus(box.stock ?? 0),
    dimensions: renderBoxDimensions(box.boxType.dimensions, true),
    description: box.boxType.shortDescription,
    regularPrice: convertNumberToDisplayablePriceString(
      box.allTaxesIncludedPrice
    ),
    // discountPrice?: string | number, // TODO discount price
    bookingFeesPrice: convertNumberToDisplayablePriceString(
      retrieveBoxBookingFees(box)?.allTaxesIncludedPrice
    ),
    path: renderProductUrl(box.path!),
    onAddToCart,
  };
}