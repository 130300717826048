import type { ResponseCartDTO } from '@repo/api-client';
import mitt, { type Emitter } from 'mitt';
import { CartItemWriter } from '../storage-writer/cart-item-writer';
import { CartClient } from './cart-client';
import { CartClientError } from './cart-client-error';
import { type CartEvents } from './cart-events';
import {
  type CartClientRequestOptions,
  type CartControllerInitParams,
  type CartControllerLineParams,
} from './types';
import { reportRemoveFromCart } from '@/lib/analytics';

const maxRetry = 3;

/**
 * S'occupe de fournir des abstractions pour faciliter la manipulation des paniers depuis l'Interface Graphique.
 */
export class CartController {
  public readonly emitter: Emitter<CartEvents> = mitt<CartEvents>();
  private readonly client: CartClient;
  private readonly storage: CartItemWriter = new CartItemWriter();
  /**
   * An optional cart id that will be used to choose which cart to interact with.
   * If not set, the cartController will handle localStorage carts depending on which centers
   * we add products from.
   *
   * @private
   * @type {string}
   * @memberof CartController
   */
  private cartId?: string;

  private retryCount: number = 0;

  constructor(params: CartControllerInitParams) {
    this.client = new CartClient(params.axios);
    this.cartId = params.cartId;
    this.initListeners();
  }

  private initListeners() {
    // Sync the updates with the storage
    this.emitter.on('cartUpdated', (cart) => {
      if (!cart) return;

      // TODO temp : mono cart to avoid bugs with multi center carts
      this.storage.clear();
      this.storage.set({
        cartId: cart.id,
        centerId: cart.centerId,
      });
    });

    this.emitter.on('error', (error) => {
      // If the cart is not found, it means it has been deleted on the server, so we remove it from the client
      if (error.errorType === 'NOT_FOUND') {
        if (!error.cartId) return;

        this.storage.deleteByCartId(error.cartId);
      }
    });
  }

  public useCartId(cartId?: string) {
    this.cartId = cartId;
    this.getCart().then((result) => this.emitter.emit('cartUpdated', result));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private retry<T extends (...args: any) => any>(
    cb: T
  ): ReturnType<T> | undefined {
    if (this.retryCount >= maxRetry) return;

    if (this.retryCount === 0)
      setTimeout(() => {
        this.retryCount = 0;
      }, 1_000); // Reset retry in 1s

    this.retryCount += 1;
    cb.bind(this);
    return cb();
  }

  private handleError(
    e: unknown,
    params?: { centerId?: string; cartId?: string }
  ): CartClientError {
    if (!(e instanceof CartClientError)) {
      this.emitter.emit('notice', {
        type: 'ERROR',
        message: 'Une erreur inattendue est survenue.',
      });

      return CartClientError.Unknown();
    }

    this.emitter.emit('notice', {
      type: 'ERROR',
      message: e.message,
    });

    if (e.type === 'BAD_REQUEST') {
      // On essaye 3x
      const attempt = this.retry(() => this.getCart());
      if (attempt) {
        attempt.then((cart) => {
          if (!cart) return;

          this.emitter.emit('error', {
            errorType: 'BAD_REQUEST',
            restoredCart: cart,
          });
        });
      }
      // Au bout de 3 échecs
      else {
        const cartId = params?.cartId ?? this.cartId;
        const centerId = params?.centerId;
        if (cartId || centerId) {
          if (cartId) {
            this.storage.deleteByCartId(cartId);
          } else {
            this.storage.deleteByCenterId(centerId!);
          }
          this.storage.write();
          this.cartId = undefined;
          this.emitter.emit('error', {
            errorType: 'FATAL_ERROR',
          });
        }
      }
    } else if (e.type === 'NOT_FOUND') {
      this.cartId = undefined;
      this.emitter.emit('error', {
        errorType: 'NOT_FOUND',
        cartId: e.cartId,
      });
    }

    return e;
  }

  /**
   * Loads a cart from the API using its ID or throw an error if there is some
   * @param cartId
   * @returns
   */
  private async loadCart(cartId: string): Promise<ResponseCartDTO | undefined> {
    if (!cartId) return;

    return this.client.get(cartId);
  }

  /**
   * Apply a coupon to the current cart using its code
   *
   * @param code
   * @returns
   */
  async addCoupon(
    code: string,
    centerId: string
  ): Promise<ResponseCartDTO | CartClientError> {
    this.emitter.emit('loading');

    try {
      const cart = await this.getCart(centerId);

      if (!cart) throw CartClientError.NoCart();

      const updatedCart = await this.client.update(cart.id, {
        ...cart,
        coupons: [...(cart.coupons ?? []), code],
      });

      this.emitter.emit('cartUpdated', updatedCart);
      return updatedCart;
    } catch (e) {
      return this.handleError(e);
    }
  }

  /**
   * Removes a coupon from the current cart using its code
   *
   * @param code
   */
  async removeCoupon(
    code: string,
    centerId: string
  ): Promise<ResponseCartDTO | CartClientError> {
    this.emitter.emit('loading');

    try {
      const cart = await this.getCart(centerId);

      if (!cart) throw CartClientError.NoCart();

      if (!cart.coupons)
        throw new CartClientError({
          type: 'UNKNOWN',
          message: "Il n'y aucun coupon dans le panier",
        });

      // Removes the coupon from the cart coupons
      const coupons = cart.coupons
        .filter((coupon) => coupon.code !== code)
        .map((coupon) => coupon.code);

      const updatedCart = await this.client.update(cart.id, {
        ...cart,
        coupons,
      });

      this.emitter.emit('cartUpdated', updatedCart);
      return updatedCart;
    } catch (e) {
      return this.handleError(e);
    }
  }

  /**
   * Adds a line to the current cart or create a new cart
   *
   * @param params
   */
  async addLine(
    params: CartControllerLineParams
  ): Promise<ResponseCartDTO | CartClientError> {
    this.emitter.emit('loading');

    const requestOptions: CartClientRequestOptions = {
      mergeLines: true,
    };

    const lineData = {
      productId: params.productId,
      qty: params.qty ?? 1,
    };

    try {
      const cart = await this.getCart(params.centerId);

      const updatedCart = await this.client.createOrUpdate(
        {
          ...(cart ?? {
            centerId: params.centerId,
          }),
          productLines: [...(cart?.productLines ?? []), lineData],
        },
        requestOptions
      );

      this.emitter.emit('cartUpdated', updatedCart);
      return updatedCart;
    } catch (e) {
      return this.handleError(e);
    }
  }

  /**
   * Updates a line from the current cart
   *
   * @param params
   * @returns
   */
  async updateLine(
    params: CartControllerLineParams
  ): Promise<ResponseCartDTO | CartClientError> {
    this.emitter.emit('loading');

    const requestOptions: CartClientRequestOptions = {
      mergeLines: false,
    };

    const lineData = {
      productId: params.productId,
      qty: params.qty ?? 1,
    };

    try {
      const cart = await this.getCart(params.centerId);

      if (!cart) throw CartClientError.NoCart();

      const updatedCart = await this.client.update(
        cart.id,
        {
          ...cart,
          productLines: [...cart.productLines, lineData],
        },
        requestOptions
      );

      this.emitter.emit('cartUpdated', updatedCart);
      return updatedCart;
    } catch (e) {
      return this.handleError(e);
    }
  }

  /**
   * Removes a line from the current cart
   *
   * @param productId
   * @returns
   */
  async removeLine(
    lineId: string,
    centerId: string
  ): Promise<ResponseCartDTO | CartClientError> {
    this.emitter.emit('loading');

    const requestOptions: CartClientRequestOptions = {
      mergeLines: true,
    };

    try {
      const cart = await this.getCart(centerId);

      if (!cart) throw CartClientError.NoCart();

      const productLines = cart.productLines.filter(
        (line) => line.id !== lineId
      );

      const updatedCart = await this.client.update(
        cart.id,
        {
          ...cart,
          productLines,
        },
        requestOptions
      );

      this.emitter.emit('cartUpdated', updatedCart);

      // Send GTM
      reportRemoveFromCart({cart: updatedCart});

      return updatedCart;
    } catch (e) {
      return this.handleError(e);
    }
  }

  /**
   * Gets the number of products contained in the cart
   *
   * @returns
   */
  async getProductCount(): Promise<number | undefined> {
    const cart = await this.getCart();
    return cart?.productLines?.length;
  }

  /**
   * Gets the active cart
   *
   * @returns
   */
  async getCart(centerId?: string): Promise<ResponseCartDTO | undefined> {
    const cartId =
      this.cartId ??
      (centerId
        ? this.storage.getByCenterId(centerId)?.cartId
        : this.storage.getLast()?.cartId);

    try {
      if (!cartId) throw CartClientError.NoCart();

      return await this.loadCart(cartId);
    } catch (e) {
      this.handleError(e, { centerId, cartId });
    }
  }

  getCartCount(): number | undefined {
    return this.storage.listItems()?.length;
  }

  async listCarts(): Promise<ResponseCartDTO[] | undefined> {
    const items = this.storage.listItems();

    if (!items) return;

    const carts = await Promise.all(
      items.map((item) => this.loadCart(item.cartId))
    );

    return carts.filter((cart) => cart !== undefined) as ResponseCartDTO[];
  }

  async getCheckoutUrl(): Promise<string | undefined> {
    const cart = await this.getCart();
    if (!cart) return undefined;

    return `/commande?cartId=${cart.id}`;
  }
}
