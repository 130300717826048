import { formatCenterName } from "@repo/utils";
import type {
  ResponseCartDTO,
} from '@repo/api-client';
import type { CenterInfos, ProductLineInfos } from "./types";
import type { RenderBoxNameParams} from "@/utils";
import { renderBoxNameString } from "@/utils";

/**
 * Format a cart line data for analytics reporting 
 * 
 * @param infos 
 * @returns 
 */
export function formatProductLine(infos: ProductLineInfos) {
  const centerName = formatCenterName(infos.center);
  const output: Record<string, unknown> = {
    item_id: infos.line.productId,
    item_name: formatProductName(infos.line.product),
    item_brand: centerName,
    affiliation: centerName,
    price: formatPrice(infos.line.product.allTaxesIncludedPrice),
    // tax --> ne pas mettre on veut du TTC
    quantity: infos.line.qty ?? 1,
  };

  const discount = infos.line.totals?.discountsTotal?.allTaxesIncluded
    ? Math.abs(infos.line.totals?.discountsTotal?.allTaxesIncluded)
    : undefined;

  if (discount !== undefined)
    output.discount = discount;

  if (infos.line.coupons?.[0]) 
    output.coupon = infos.line.coupons?.[0];

  return output;
}

type FormatProductDataArgs = {
  center: CenterInfos, 
  product: ProductLineInfos['line']['product'] & {id: string, centerId: string},
  index?: number,
}

export function formatProductData(args: FormatProductDataArgs) {
  return formatProductLine({
    center: args.center,
    index: args.index ?? 1,
    line: {
      product: args.product,
      productId: args.product.id,
    }
  })
}

/**
 * Format a cart product lines for analytics reporting
 * @param cart 
 * @returns 
 */
export function formatCartProductLines(cart: ResponseCartDTO) {
  return cart.productLines
    .map((line, index) =>
      formatProductLine({ line, index, center: cart.center }),
    )
}

/**
 * Formats the price for analytics reporting
 * 
 * @param price 
 * @returns 
 */
export function formatPrice(price: number): number {
  return parseFloat(price.toFixed(2));
}

export type FormatProductNameArgs = {name: string} | RenderBoxNameParams;

/**
 * Formats the product name for analytics reporting
 * 
 * @param product 
 * @returns 
 */
export function formatProductName(product: FormatProductNameArgs): string {
  if ('name' in product) return product.name;

  return `Box ${renderBoxNameString(product)}`;
}