import _slugify from "slugify";

/**
 * Tells to replace keys by values
 */
_slugify.extend({
  '%' : '',
  '+' : '-plus',
  ',' : '_',
  '.' : '_'
});

/**
 * Converts a string to a slug.
 *
 * @example "TVA 20%" --> "tva-20"
 *
 * @param subject
 */
export function slugify (subject: string): string {
  subject = subject.replace(/[a-z][A-Z]/g, (value) => {
    return value.split('').join(' ').toLowerCase();
  });

  return _slugify(subject, {lower: true,});
}
