export class CartClientError {
  type!: 'NOT_FOUND' | 'UNKNOWN' | 'BAD_REQUEST';
  message!: string;
  cartId?: string;

  constructor(data: CartClientError) {
    Object.assign(this, data);
  }

  static Unknown(): CartClientError {
    return new CartClientError({
      type: 'UNKNOWN',
      message:
        "Une erreur inattendue est survenue, nos équipes s'emploient à régler ce problème au plus vite. Veuillez nous excuser pour la gêne occasionnée.",
    });
  }

  static NoCart(): CartClientError {
    return new CartClientError({
      type: 'UNKNOWN',
      message: "Il n'y a aucun panier sur lequel effectuer cette opération"
    })
  }
}