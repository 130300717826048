import { type StorageWriterInterface } from './storage-writer-interface';

export class LocalStorageWriter<T> implements StorageWriterInterface<T> {
  constructor(private readonly key: string) {}

  private storageAvailable() : boolean {
    try {
      if (typeof window === 'undefined' || !window?.localStorage) return false;

      localStorage.setItem('__local_storage_enabled__', 'test');
      localStorage.removeItem('__local_storage_enabled__');
      return true;
    } catch(e) {
        return false;
    }
  }

  read(): T | undefined {
    if (!this.storageAvailable()) return;

    const item = window.localStorage.getItem(this.key);
    if (!item) return;

    try {
      return JSON.parse(item);
    } catch (e) {
      return undefined;
    }
  }

  write(data?: T): void {
    if (!this.storageAvailable()) return;

    if (!data) {
      window.localStorage.removeItem(this.key);
    } else {
      window.localStorage.setItem(this.key, JSON.stringify(data));
    }
  }
}
