import Decimal from "decimal.js";


/**
 * Converts a number to a Price by rounding it to 2 decimals
 * @param n
 */
export function convertNumberToDisplayablePrice( n: number ) : number {
  return new Decimal(n).toNearest(0.01).toNumber();
}

export function convertNumberToPrice( n: number ) : number {
  return parseFloat(n.toFixed(4));
}

export function convertNumberToStripePrice(amount: number) : number {
  return parseInt((amount * 100).toFixed());
}

/**
 * Converts a number to a price string
 *
 * @example 100 --> '100,00'
 * @example 97.47 --> '97,47'
 *
 * @param n
 */
export function convertNumberToDisplayablePriceString (n?: number, decimals:number = 2) : string {
  if (n === undefined) 
    return '';
  return convertNumberToPrice(n).toFixed(decimals).replace('.', ',');
}

export function computeTTCPrice(price: number, taxClass: {rate: number}) : number {
  return price * (1 + taxClass.rate / 100);
}