/**
 * Rounds a date to 00h00
 * @param date
 */
export function roundDate(date: Date): Date {
  return new Date(date.toISOString().split('T')[0]!);
}

/**
 * Add days to a date
 * @param date
 * @param days
 */
export function addDays(date: Date, days: number): Date {
  const output = new Date(date);
  output.setDate(date.getDate() + days);
  return output;
}

export function convertDateToFrenchFormat(date: Date): string {
  return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}/${date.getFullYear()}`;
}

export function convertDateToInputFormat(date?: Date): string {
  if (!date)
    return '';
  
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function parseDateFromInput(value: string): Date | undefined {
  const parsed = Date.parse(value);

  if (isNaN(parsed)) return;

  return new Date(parsed);
}

/**
 * Gets the total number of days in the current month for a given date 
 * @param d 
 * @returns 
 */
export function getNumberOfDaysInTheMonth( d: Date ) : number {
  return new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate();
}

export function addOneMonth( d: Date ) : Date {
  const date = new Date(d);
  date.setMonth( d.getMonth() + 1 );
  date.setDate( d.getDate() );

  return date;
}