import type { CheckoutInfos } from '@/components/checkout/types';
import { sendGTMEvent } from '@next/third-parties/google';
import type {
  BoxProductCardData,
  BoxProductEntity,
  CenterEntity,
  ProductEntity,
  ResponseCartDTO,
} from '@repo/api-client';
import { formatCenterName } from '@repo/utils';
import dayjs from 'dayjs';
import type { AgenceData, CenterInfos, UserData, UserLeadData } from './types';
import {
  formatCartProductLines,
  formatPrice,
  formatProductData,
} from './analytics-utils';
import { captureError } from '@/utils/error-utils';
import { hashSHA256 } from '../hash';

/**
 * Reference 
 * https://docs.google.com/spreadsheets/d/1y8Bw2fZe2LqNdeOtoFu8NdC9kfGqXWBQskYQ0qtq4u4/edit#gid=1748174593
 * 
 * Implemented 03/2024
 */

/**
 * Reports the checkout begin event to analytics services
 * 
 * @param args 
 */
export function reportCheckoutBegin(args: { cart: ResponseCartDTO }) {
  try {
    if (args.cart?.totals?.total?.allTaxesIncluded === undefined) 
      throw new Error('reportCheckoutBegin: cart.totals.total.allTaxesIncluded is undefined');

    const centerName = formatCenterName(args.cart.center);

    const payload = {
      currency: 'EUR',
      value: formatPrice(args.cart.totals.total.allTaxesIncluded),
      agence_id: args.cart.centerId,
      agence: centerName,
      items: formatCartProductLines(args.cart),
    };

    sendGTMEvent({
      event: 'begin_checkout',
      ecommerce: payload
    });
  } catch(e) {
    captureError(e);
  }
}

/**
 * Report the checkout booking step data to analytics services
 * @param args
 */
export function reportCheckoutBookingStep(args: {
  infos: CheckoutInfos;
  center: CenterInfos;
  centerId: string;
  startingDate: Date;
}) {
  try {
    sendGTMEvent({
      event: 'add_date',
      agence_id: args.centerId,
      agence: formatCenterName(args.center),
      // Compute the number of days the user choosed to take before his contracts starts
      starting_date_delta: Math.abs(
        Math.floor(
          dayjs(args.startingDate).diff(
            args.infos.startingDateLimits.min,
            'day',
            true,
          ),
        ),
      ),
    });
  } catch (e) {
    reportError(e);
  }
}



/**
 * Report the checkout infos step data to analytics services
 * @param args
 */
export async function reportCheckoutInfosStep(args: { cart: ResponseCartDTO, user: UserData }) {
  // Report the add_shipping infos
  try {
    if (!args.cart.totals?.total)
      throw new Error('reportCheckoutInfosStep : cart.totals is not defined');

    const payload: Record<string, unknown> = {
      currency: 'EUR',
      value: args.cart.totals.total.allTaxesIncluded,
      agence_id: args.cart.centerId,
      agence: formatCenterName(args.cart.center),
      items: formatCartProductLines(args.cart),
    };

    if (args.cart.coupons?.[0]?.code)
      payload.coupon = args.cart.coupons?.[0]?.code;

    const [email, phoneNumber, firstname, lastname, streetAddress] = await hashSHA256(
      args.user.email,
      args.user.phone,
      args.user.firstName,
      args.user.lastName,
      args.user.streetAddress,
    );

    sendGTMEvent({
      event: 'add_shipping_info',
      ecommerce: payload,
      'leadsUserData': {
        'sha256_email_address': email,
        'sha256_phone_number': phoneNumber,
        'address': {
          sha265_first_name: firstname,
          sha256_last_name: lastname,
          sha256_street: streetAddress,
          postal_code: args.user.postalCode,
          city: args.user.city,
          country: args.user.country
        },
      },
    });
  } catch (e) {
    captureError(e);
  }
}

/**
 * Reports the add payment info checkout step to analytics services
 * @param args 
 */
export async function reportCheckoutAddPaymentInfos(args: { cart: ResponseCartDTO, orderId?: string, user: UserData }) {
  try {
    if (args.cart.totals?.total?.allTaxesIncluded === undefined)
      throw new Error('reportCheckoutAddPaymentInfo: cart.totals.total.allTaxesIncluded is undefined');

    const payload = {
      currency: 'EUR',
      transaction_id: args.orderId ?? 'NULL',
      value: formatPrice(args.cart.totals.total.allTaxesIncluded),
      agence_id: args.cart.centerId,
      agence: formatCenterName(args.cart.center),
      coupon: args.cart.coupons?.[0]?.code,
      items: formatCartProductLines(args.cart),
    };

    const [email, phoneNumber, firstname, lastname, streetAddress] = await hashSHA256(
      args.user.email,
      args.user.phone,
      args.user.firstName,
      args.user.lastName,
      args.user.streetAddress,
    );
    
    sendGTMEvent({
      event: 'add_payment_info',
      ecommerce: payload,
      'leadsUserData': {
        'sha256_email_address': email,
        'sha256_phone_number': phoneNumber,
        'address': {
          sha265_first_name: firstname,
          sha256_last_name: lastname,
          sha256_street: streetAddress,
          postal_code: args.user.postalCode,
          city: args.user.city,
          country: args.user.country
        },
      },
    });
  } catch (e) {
    captureError(e);
  }
}

/**
 * Report the purchase event to analytics services
 * @param args 
 * @see https://developers.google.com/analytics/devguides/collection/ga4/uid-data?hl=fr
 */
export async function reportCheckoutPurchase(args: {cart: ResponseCartDTO, orderId: string, user: UserData}) {
  try {
    if (args.cart.totals?.total?.allTaxesIncluded === undefined)
      throw new Error('reportCheckoutPurchase: cart.totals?.total?.allTaxesIncluded is undefined');

    const payload = {
      currency: 'EUR',
      value: formatPrice(args.cart.totals.total.allTaxesIncluded),
      // tax: ne pas mettre, on veut du ttc 
      agence_id: args.cart.centerId,
      agence: formatCenterName(args.cart.center),
      coupon: args.cart.coupons?.[0]?.code,
      items: formatCartProductLines(args.cart),
      transaction_id: args.orderId,
    };

    const [email, phoneNumber, firstname, lastname, streetAddress] = await hashSHA256(
      args.user.email,
      args.user.phone,
      args.user.firstName,
      args.user.lastName,
      args.user.streetAddress,
    );

    // https://developers.google.com/analytics/devguides/collection/ga4/uid-data?hl=fr
    sendGTMEvent({
      event: 'purchase',
      ecommerce: payload,
      'leadsUserData': {
        'sha256_email_address': email,
        'sha256_phone_number': phoneNumber,
        'address': {
          sha265_first_name: firstname,
          sha256_last_name: lastname,
          sha256_street: streetAddress,
          postal_code: args.user.postalCode,
          city: args.user.city,
          country: args.user.country
        },
      },
    });
  } catch (e) {
    captureError(e);
  }
}

/**
 * Report the add to cart event to analytics services
 * @param args
 */
export function reportAddToCart(args: { cart: ResponseCartDTO }) {
  try {
    if (!args.cart.totals?.total)
      throw new Error('reportAddToCart : cart.totals.total is not defined');

    const payload = {
      currency: 'EUR',
      value: args.cart.totals?.total?.allTaxesIncluded,
      agence_id: args.cart.centerId,
      agence: formatCenterName(args.cart.center),
      items: formatCartProductLines(args.cart),
    };

    sendGTMEvent({
      event: 'add_to_cart',
      ecommerce: payload,
    });
  } catch (e) {
    captureError(e);
  }
}

/**
 * Report a contact form submission to analytics services
 * @param args
 */
export async function reportContactLead(args: {
  center: CenterInfos;
  centerId: string;
  userData: UserLeadData
}) {
  const agence = formatCenterName(args.center);

  const [email, phoneNumber] = await hashSHA256(
    args.userData.email,
    args.userData.phone
  );
  
  sendGTMEvent({
    event: 'generate_lead_contact',
    agence_id: args.centerId,
    agence,
    leadsUserData: {
      'sha256_email_address': email,
      'sha256_phone_number': phoneNumber,
    },
  });
}

/**
 * Report a visit form submission to analytics services
 * @param args
 */
export async function reportVisitLead(args: {
  center: CenterInfos;
  centerId: string;
  userData: Pick<UserData, 'email' | 'phone'>
}) {
  const agence = formatCenterName(args.center);

  const [email, phoneNumber] = await hashSHA256(
    args.userData.email,
    args.userData.phone
  );
  
  sendGTMEvent({
    event: 'generate_lead_visite',
    agence_id: args.centerId,
    agence,
    leadsUserData: {
      'sha256_email_address': email,
      'sha256_phone_number': phoneNumber,
    },
  });
}

/**
 * Report a visit form submission to analytics services
 * @param args
 */
export async function reportRecallLead(args: {
  center: CenterInfos;
  centerId: string;
  userData: Pick<UserData, 'email' | 'phone'>
}) {
  const agence = formatCenterName(args.center);

  const [email, phoneNumber] = await hashSHA256(
    args.userData.email,
    args.userData.phone
  );
  
  sendGTMEvent({
    event: 'generate_lead_rappel',
    agence_id: args.centerId,
    agence,
    leadsUserData: {
      'sha256_email_address': email,
      'sha256_phone_number': phoneNumber,
    },
  });
}

/**
 * Report a waiting list form submission event
 * @param args
 */
export async function reportWaitingListLead(args: {
  product: ProductEntity | BoxProductEntity;
  center: CenterInfos;
  userData: Pick<UserData, 'email' | 'phone'>
}) {
  try {
    const payload = {
      currency: 'EUR',
      value: formatPrice(args.product.allTaxesIncludedPrice),
      agence_id: args.product.centerId,
      agence: formatCenterName(args.center),
      items: [
        formatProductData({
          center: args.center,
          product: args.product,
        }),
      ],
    };

    const [email, phoneNumber] = await hashSHA256(
      args.userData.email,
      args.userData.phone
    );

    sendGTMEvent({
      event: 'generate_lead_attente',
      ecommerce: payload,
      leadsUserData: {
        'sha256_email_address': email,
        'sha256_phone_number': phoneNumber,
      },
    });
  } catch (e) {
    captureError(e);
  }
}

/**
 * Report Quote button event to analytics services
 * @param args 
 */
export function reportQuoteLead(args: {
  center: CenterInfos;
  centerId: string;
}) {
  const agence = formatCenterName(args.center);

  sendGTMEvent({
    event: 'generate_lead_devis',
    agence_id: args.centerId,
    agence,
  });
}

/**
 * Report view cart event to analytics services.
 * Ecommerce data has been deleted to let the event be triggered only when the cart modal is open
 * to avoid being triggered in the checkout page
 * @param args 
 */
export function reportViewCart() {
  sendGTMEvent({
    event: 'view_cart',
  });
}

/**
 * Remove from cart
 * 
 * @param args 
 */
export function reportRemoveFromCart(args: { cart: ResponseCartDTO }) {
  try {
    if (args.cart.totals?.total?.allTaxesIncluded === undefined)
      throw new Error('reportRemoveFromCart: cart.totals.total.allTaxesIncluded is undefined');

    const payload = {
      currency: 'EUR',
      value: formatPrice(args.cart.totals.total.allTaxesIncluded),
      agence_id: args.cart.centerId,
      agence: formatCenterName(args.cart.center),
      items: formatCartProductLines(args.cart),
    };

    sendGTMEvent({
      'event': 'remove_from_cart',
      ecommerce: payload
    });
  } catch (e) {
    captureError(e);
  }
}

type ReportViewItemListArgs = {
  center: CenterEntity, 
  products: BoxProductCardData[], 
  /**
   * Indicates if the event is triggered from the center page product grid or from the product page related slider
   */
  grid: 'center' | 'related'
}

/**
 * Report a view item list event to analytics services
 * @param args 
 */
export function reportViewItemList(args: ReportViewItemListArgs) {
  try {
    const centerName = formatCenterName(args.center);

    const payload = {
      agence_id: args.center.id,
      agence: centerName,
      item_list_id: args.grid === 'center' 
        ? 'center_product_grid'
        : 'box_related_products',
      item_list_name: args.grid === 'center' 
        ? 'Grille Produit Centre'
        : 'Grille Produit Liés Page Box',
      items: args.products.map((product, index) => {
        return formatProductData({
          product,
          center: args.center,
          index,
        })
      })
    }

    sendGTMEvent({
      event: args.grid === 'center' 
        ? 'view_item_list'
        : 'view_box_related_products',
      ecommerce: payload
    })
  } catch(e) {
    captureError(e);
  }
}

/**
 * Report a view item event to analytics services
 * @param args 
 */
export function reportViewItem(args: {box: BoxProductEntity}) {
  try {
    sendGTMEvent({
      event: 'view_item',
      ecommerce: {
        value: formatPrice(args.box.allTaxesIncludedPrice),
        items: [
          formatProductData({
            product: args.box,
            center: args.box.center
          })
        ]
      }
    })
  } catch(e) {
    captureError(e);
  }
}

/**
 * Report search event to analytics services
 * @param args 
 */
export function reportSearch(args: {query: string}) {
  sendGTMEvent({
    event: 'search',
    search_term: args.query
  });
}

/**
 * Define the current agence to the datalayer. Triggered when the user visit a page related to a center (center, box, checkout, order confirmed)
 * @param args 
 */
export function reportAgenceData(args: {center: AgenceData}) {
  sendGTMEvent({
    event: 'set_agence',
    agence_id: args.center.id,
    agence: formatCenterName(args.center),
  });
}

export function reportViewAgence(args: {center: AgenceData}) {
  sendGTMEvent({
    event: 'view_agence',
    agence_id: args.center.id,
    agence: formatCenterName(args.center),
  });
}